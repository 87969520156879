.editor #codeArea {
  outline: none;
  padding: 0 0 0 3.75rem !important;
}

.editor pre {
  padding: 0 0 0 3.75rem !important;
}

.editor .editorLineNumber {
  position: absolute;
  padding-right: 0.5rem;
  left: 0;
  color: #333;
  background: #ededed;
  text-align: right;
  width: 3.25rem;
  font-weight: 100;
}

.editor .editorLineNumberError {
  background: #ce7c78;
}

.editor .editorLineValue {
  display: inline-block;
  width: 100%;
}

.editor .editorLineValueError {
  background: rgba(206, 124, 120, 0.5);
}

.editor-error-tooltip {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  right: calc(100% + 0.3215rem);
  background: #95200d;
  padding: 0.25rem 0.5rem;
  z-index: 9;
  white-space: nowrap;
  font-size: 0.6875rem;
  color: #fff;
  font-weight: 600;
  border-radius: 0.25rem;
}

.editor-error-tooltip:after {
  content: '';
  position: absolute;
  right: -0.1875rem;
  width: 0.375rem;
  height: 0.375rem;
  transform: rotate(45deg);
  background: #95200d;
  top: 50%;
  margin-top: -0.1875rem;
}

.editor-duplicate-tooltip {
  background: #147ebe;
}
.editor-duplicate-tooltip-step2 {
  margin-top: -0.1875rem;
}
.editor-duplicate-tooltip:after {
  background: #147ebe;
}
